import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

const IndexPage = () => (
  <Layout>
    <div className="index-page">
      <Helmet bodyAttributes={{ class: 'home', }} />

      <h2>Digital solutions</h2>
      <p>I build cool and unusual things for the web.</p>

      <Link to="/about/" className="btn btn-yellow">what?</Link>
      <Link to="/projects/" className="btn btn-blue">show me more</Link>
      <Link to="/contact/" className="btn btn-green">let's do this!</Link>
    </div>
  </Layout>
)

export default IndexPage
